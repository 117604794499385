"use client";

import React from "react";
import Navbar from "../common/Navbar";
import { TanstackProvider } from "../../app/tanstack-provider";
import PageWrapper from "./PageWrapper";
import { FormProvider } from "../../context/FormContext";

export default function ClientLayout({ children }) {
  return (
    <TanstackProvider>
      <PageWrapper>
        <FormProvider>
          <Navbar />
          {children}
        </FormProvider>
      </PageWrapper>
    </TanstackProvider>
  );
}
