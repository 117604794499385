"use client";
import { usePathname } from "next/navigation";

export default function PageWrapper({ children }) {
  const pathname = usePathname();
  let hideNavbar = false;
  if (pathname.startsWith("/property")) {
    hideNavbar = true;
  } else if (pathname.startsWith("/tenant")) {
    hideNavbar = true;
  } else if (pathname.startsWith("/tenant/login")) {
    hideNavbar = false;
  }
  return <main className={hideNavbar ? "" : "pt-20"}>{children}</main>;
}
