"use client";

import { useState, useEffect } from "react";
import Image from "next/image";
import { NAV_LINKS } from "../../constants/data";
import Link from "next/link";
import { User } from "lucide-react";
import Backdrop from "./Backdrop";
import { usePathname, useRouter } from "next/navigation";

export default function Navbar() {
  const pathname = usePathname();
  let hideNavbar =
    pathname.startsWith("/property") || pathname.startsWith("/tenant");
  if (pathname.startsWith("/tenant/login")) hideNavbar = false;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const token = localStorage.getItem("tenant-token");
    setIsAuthenticated(!!token);
  }, []);

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    if (confirmLogout) {
      localStorage.clear();
      router.push("/tenant/login");
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    const newPages = ["pricing", "insights", "faqs", "contact-us"];
    const page = sectionId.replace("#", "").toLowerCase();
    console.log("page", page);

    if (newPages.includes(page)) {
      window.location.href = `/${page}`;
      return;
    }

    const section = document.querySelector(sectionId);
    if (section) {
      const navHeight = 100;
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      setActiveSection(sectionId);
    } else {
      window.location.href = "/?section=" + sectionId.replace("#", "");
    }
    setIsMenuOpen(false);
  };

  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);

      const sections = NAV_LINKS.map((item) =>
        document.querySelector(`#${item.replace(/\s+/g, "").toLowerCase()}`)
      ).filter(Boolean);

      let currentSectionId = "";
      let minDistance = Infinity;

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const distance = Math.abs(rect.top - 100);

        if (distance < minDistance) {
          minDistance = distance;
          currentSectionId = section.id;
        }
      });

      if (currentSectionId) {
        setActiveSection(`#${currentSectionId}`);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Set active section based on current pathname for direct navigation
  useEffect(() => {
    if (pathname === "/pricing") {
      setActiveSection("#pricing");
    } else if (pathname === "/insights") {
      setActiveSection("#insights");
    } else if (pathname === "/faqs") {
      setActiveSection("#faqs");
    } else if (pathname === "/contact-us") {
      setActiveSection("#contact-us");
    } else if (pathname === "/") {
      // Reset active section when on home page
      setActiveSection("");
    }
  }, [pathname]);

  useEffect(() => {
    console.log("pathname", pathname);
    if (pathname.startsWith("/tenant/login")) {
      setActiveSection("");
    }
    if (pathname.startsWith("/insights")) {
      router.push("/Insights");
    }
  }, [pathname, router]);

  const goToPage = (page) => {
    setIsMenuOpen(false);
    const pages = ["pricing", "insights", "faqs", "contact-us"];
    console.log("page", page, pages.includes(page));
    if (pages.includes(page)) {
      window.location.href = `/${page}`;
    } else {
      window.location.href = `/#${page}`;
    }
  };
  if (hideNavbar) return null;
  return (
    <>
      <Backdrop isOpen={isMenuOpen} onClose={toggleMenu} zIndex="z-[50]" />

      <nav
        className={`fixed top-0 left-0 right-0 z-[60] transition-all duration-300
        ${scrolled ? "bg-white/90 backdrop-blur-md shadow-lg" : "bg-transparent"}`}
      >
        <div className="max-w-[1920px] px-5 md:px-20 mx-auto">
          <div className="flex items-center justify-between h-20">
            <Link
              href="/"
              className="flex-shrink-0 transform hover:scale-105 transition-transform duration-200"
            >
              <Image
                src="/main10_green.png"
                width={103}
                height={27}
                alt="logo"
                className="h-8 w-auto"
              />
            </Link>

            <div className="hidden lg:flex items-center space-x-8">
              {NAV_LINKS.map((item, index) => (
                <button
                  key={index}
                  onClick={() => {
                    console.log("item", item);
                    scrollToSection(
                      `#${item.replace(/\s+/g, "").toLowerCase()}`
                    );
                  }}
                  className={`relative px-3 py-2 text-sm font-medium transition-colors duration-300 ease-in-out
                    group hover:text-primary
                    ${
                      activeSection ===
                      `#${item.replace(/\s+/g, "").toLowerCase()}`
                        ? "text-primary"
                        : "text-gray-600"
                    }`}
                  aria-label={`Go to ${item} section`}
                >
                  {item}
                  <span
                    className={`absolute bottom-0 left-0 w-full h-0.5 bg-primary transform origin-left 
                    transition-transform duration-300 ease-in-out scale-x-0 group-hover:scale-x-100
                    ${activeSection === `#${item.replace(/\s+/g, "").toLowerCase()}` ? "scale-x-100" : ""}`}
                  />
                </button>
              ))}
              {isAuthenticated ? (
                <div
                  className="relative"
                  onMouseEnter={() => setShowAccountMenu(true)}
                  onMouseLeave={() => setShowAccountMenu(false)}
                >
                  <button className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-600 hover:text-primary transition-colors duration-200">
                    <User className="w-5 h-5" />
                    <span>Account</span>
                    <svg
                      className={`w-4 h-4 transition-transform duration-200 ${showAccountMenu ? "rotate-180" : ""}`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>

                  <div
                    className={`absolute right-0 mt-2 w-48 bg-white/90 backdrop-blur-md rounded-lg shadow-lg py-2 transition-all duration-200 border border-gray-100/20 ${
                      showAccountMenu
                        ? "opacity-100 visible translate-y-0"
                        : "opacity-0 invisible -translate-y-2"
                    }`}
                  >
                    <Link
                      href="/tenant/dashboard"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-primary/5 hover:text-primary transition-colors duration-200"
                    >
                      Dashboard
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-primary/5 hover:text-primary transition-colors duration-200"
                    >
                      Sign Out
                    </button>
                  </div>
                </div>
              ) : (
                <Link
                  href="/tenant/login"
                  className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-primary rounded-lg hover:bg-primary/90 transition-colors duration-200"
                >
                  Login
                </Link>
              )}
            </div>

            <button
              onClick={toggleMenu}
              className="lg:hidden p-2 rounded-lg hover:bg-gray-100 transition-colors duration-200"
              aria-label="Toggle menu"
            >
              <Image
                src="/hamburger.svg"
                width={24}
                height={24}
                alt="menu"
                className="w-6 h-6"
              />
            </button>
          </div>
        </div>
      </nav>

      <div
        className={`fixed inset-0 z-[70] transform transition-transform duration-300 lg:hidden
          ${isMenuOpen ? "translate-x-0" : "translate-x-full"}`}
      >
        <div className="absolute inset-0 bg-white shadow-xl">
          <div className="flex flex-col h-full">
            <div className="flex items-center justify-between px-5 h-20 border-b border-gray-100">
              <Link href="/" className="flex-shrink-0">
                <Image
                  src="/main10_green.png"
                  width={103}
                  height={27}
                  alt="logo"
                  className="h-8 w-auto"
                />
              </Link>
              <button
                onClick={toggleMenu}
                className="p-2 rounded-lg hover:bg-gray-100 transition-colors duration-200"
                aria-label="Close menu"
              >
                <Image
                  src="/close.svg"
                  width={24}
                  height={24}
                  alt="close"
                  className="w-6 h-6"
                />
              </button>
            </div>

            <div className="flex-1 overflow-y-auto py-8 px-5">
              <div className="flex flex-col space-y-6">
                {NAV_LINKS.map((item, index) => (
                  <button
                    key={index}
                    onClick={() =>
                      goToPage(item.replace(/\s+/g, "").toLowerCase())
                    }
                    className={`text-left text-lg font-medium transition-colors duration-200
                      ${activeSection === `#${item.replace(/\s+/g, "").toLowerCase()}` ? "text-primary" : "text-gray-600 hover:text-primary"}`}
                  >
                    {item === "Contact-Us" ? "Contact us" : item}
                  </button>
                ))}
                <Link
                  href="/tenant/login"
                  className="inline-flex items-center justify-center px-5 py-3 mt-4 bg-primary 
                    text-white rounded-xl font-medium hover:bg-primary/90 transition-colors duration-200"
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
