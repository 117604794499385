import(/* webpackMode: "eager" */ "/var/www/main10.in/frontend/main10.in/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Montserrat_Alternates\",\"arguments\":[{\"variable\":\"--font-montserrat\",\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/var/www/main10.in/frontend/main10.in/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Syne\",\"arguments\":[{\"variable\":\"--font-syne\",\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"]}],\"variableName\":\"syne\"}");
;
import(/* webpackMode: "eager" */ "/var/www/main10.in/frontend/main10.in/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"variable\":\"--font-plus_jakarta_sans\",\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"]}],\"variableName\":\"plus_jakarta_sans\"}");
;
import(/* webpackMode: "eager" */ "/var/www/main10.in/frontend/main10.in/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Poppins\",\"arguments\":[{\"variable\":\"--font-poppins\",\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/var/www/main10.in/frontend/main10.in/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/var/www/main10.in/frontend/main10.in/node_modules/next/font/local/target.css?{\"path\":\"src/fonts/satoshi.js\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Satoshi-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Satoshi-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Satoshi-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-satoshi\"}],\"variableName\":\"satoshi\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/main10.in/frontend/main10.in/src/components/layout/ClientLayout.jsx");
